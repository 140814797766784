import React from "react";
import "../styles/modalWIndow.scss";
// reac responsive modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export default function ModalWindow({
  onOpenModal,
  open,
  onCloseModal,
  children,
}) {
  return (
    <Modal open={open} onClose={onCloseModal} center>
      <div className="modal-content">{children}</div>
    </Modal>
  );
}
