import React from 'react'
import ReactDOM from 'react-dom'
import { useReducer } from "react";

import Home from "./components/Home";
import "./App.css";
import Nav from "../src/components/Nav";

// component
import Login from "./components/Login";

// context api related imports
import { appContext } from "./context/context";
import { appReducer } from "./context/reducers/appReducer";

// react router imports
import { Route, Switch } from "react-router-dom";

// custom hooks
import useToken from "./hooks/useToken";
import useUserid from "./hooks/useUserid";

function App() {
  const [appState, dispatchAppState] = useReducer(appReducer, {
    images: [],
    error: "",
  });
  const { token, setToken } = useToken();
  const { setUserId } = useUserid();

  // if not logged in
  if (!token) {
    return (
      <div className="App">
        <Nav />
        <Login setUserId={setUserId} setToken={setToken} />
      </div>
    );
  }

  return (
    <appContext.Provider value={{ appState, dispatchAppState }}>
      <div className="App">
        <Nav token={token} />
        <Switch>
          <Route exact path="/manager/" component={Home} />
        </Switch>
      </div>
    </appContext.Provider>
  );
}

export default App;
