import { useState } from "react";

export default function useUserid() {
  const getUserId = () => {
    const userIdString = localStorage.getItem("user_id");
    const userId = JSON.parse(userIdString);

    return userId;
  };

  const [userId, setUserId] = useState(getUserId());

  const saveUserId = (userId) => {
    localStorage.setItem("user_id", JSON.stringify(userId));
    setUserId(userId);
  };

  return {
    setUserId: saveUserId,
    userId,
  };
}
