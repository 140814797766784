import React from "react";
import MainContent from "./MainContent";

import "../styles/main-content-home.scss";

export default function Home() {
  return (
    <div className="home">
      <MainContent />
    </div>
  );
}
