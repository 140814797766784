import React from "react";

// reac responsive modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export default function VideoModal({
  onOpenModal,
  open,
  onCloseModal,
  children,
}) {
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      styles={{
        modal: {
          maxWidth: "unset",
          width: "70%",
          height: "90%",
          padding: "unset",
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)",
        },
        // closeButton: {
        //   background: "",
        // },
      }}
      center
    >
      <div className="modal-content">{children}</div>
    </Modal>
  );
}
