import React from "react";
import "../styles/fileUploader.scss";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react dropzone uploader
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

export default function FileUploader({
  vehicleIdInputVal,
  batchIdInputVal,
  selectedVehicleType,
  getVehicleImages,
}) {
  // getting token from localStorage
  const token = JSON.parse(localStorage.getItem("token"));

  // specify upload params and url for your files
  const getUploadParams = ({ file }) => {
    // const { file } = params;
    const formData = new FormData();

    formData.append("image[]", file);
    formData.append("user_id", JSON.parse(localStorage.getItem("user_id")));
    formData.append("vehicle_id", vehicleIdInputVal);
    formData.append("batch_id", batchIdInputVal);
    formData.append("vehicle_type", selectedVehicleType);

    return {
      url: "/api/vehicle-image-add",
      headers: {
        Authorization: `Bearer ${token}`,
        enctype: "multipart/form-data",
      },
      body: formData,
    };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = (fileWithMeta, status) => {
    let response;

    if (status === "done") {
      response = JSON.parse(fileWithMeta.xhr.response);

      if (!response.status) {
        toast.warn(response.message, { position: "top-center" });
      }
      getVehicleImages(vehicleIdInputVal, selectedVehicleType);
    }
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    files.map((f) => f.meta);
    allFiles.forEach((f) => f.remove());
  };

  return (
    <>
      <ToastContainer />
      <Dropzone
        addClassNames={{
          dropzone: "custom-dropzone-style",
          inputLabel: "input-label-style",
        }}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        accept="image/*,audio/*,video/*"
        maxFiles={30}
        inputContent="Drag Files or Click to Browse, only 30 file are acceptable"
        submitButtonContent="Done"
      />
    </>
  );
}
