import React from "react";
import "../styles/nav.scss";
import Logo from "../assets/images/logo.png";

export default function Nav({ token }) {
  return (
    <div className="nav">
      <div className="logo">
        {token ? <img src={Logo} height="80" alt="logo" /> : null}
      </div>
      <div className="logout-wrapper">
        {token && (
          <div
            className="btn"
            onClick={(e) => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            <span className="icon">
              <i className="fa fa-power-off" aria-hidden="true"></i>
            </span>
            <span className="logout-text">Log Out</span>
          </div>
        )}
      </div>
    </div>
  );
}
