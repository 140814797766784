import React, { useState } from "react";
import "../styles/authenticate.scss";

// axios instance
import axios from "../Axios/axios";
import requests from "../Axios/requests";

// react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// imports related to context
// import { appContext } from "../context/context";
import Logo from "../assets/images/logo-transparent.png";

async function loginUser(credentials) {
  return axios
    .post(requests.userLogin, {
      email: credentials.username,
      password: credentials.password,
    })
    .then((res) => {
      return res;
    });
}

export default function Login({ setToken, setUserId }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await loginUser({
      username,
      password,
    });

    if (res.data.status) {
      setToken(res.data.access_token);
      setUserId(res.data.user.id);
      toast.success(res.data.message, { position: "top-center" });
    } else if (!res.data.status) {
      toast.error(res.data.message, { position: "top-center" });
    }
  };

  return (
    <div className="login">
      <ToastContainer> </ToastContainer>
      <div className="login-box">
        <img src={Logo} className="logo" height="80" alt="logo" />
        <h4 className="title">Sign In</h4>
        <form onSubmit={handleSubmit}>
          <div className="input-control">
            <label htmlFor="email">Enter your Email</label>
            <input
              type="email"
              id="email"
              onChange={(e) => setUserName(e.target.value)}
              className="email"
              required
            />
          </div>
          <div className="input-control">
            <label htmlFor="email">Enter your Password</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              id="email"
              className="email"
              required
            />
          </div>
          <input type="submit" value="Sign In" />
        </form>
      </div>
    </div>
  );
}
