import React, { useState, useContext, useEffect } from "react";
import "../styles/main-content-home.scss";

// images
import CarCategory1 from "../assets/images/car.png";
import CarCategory2 from "../assets/images/van.png";
import CarCategory1Unactive from "../assets/images/car-unactive.png";
import CarCategory2Unactive from "../assets/images/unactive-van.png";

// axios instance
import axios from "../Axios/axios";
import requests from "../Axios/requests";

// react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react player for video play
import ReactPlayer from "react-player";

// components
import ModalWindow from "./ModalWindow";
import FileUploader from "./FileUploader";
import VideoModal from "./VideoModal";

// context
import { appContext } from "../context/context";

import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";

export default function MainContent() {
  const [selectedVehicleType, setSelectedVehicleType] = useState("cars");
  const [vehicleIdInputVal, setVehicleIdInputVal] = useState("");
  const [batchIdInputVal, setBatchIdInputVal] = useState("");
  const [error, setError] = useState("");
  const [dndList, setDndList] = useState([]);
  const { appState, dispatchAppState } = useContext(appContext);
  const [onSorting, setOnSorting] = useState(false);
  const [sortedList, setSortedList] = useState([]);
  const [items, setItems] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");

  // states for modal window for file uploading
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  // states for modal window
  const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
  const onOpenVideoModal = () => setOpenVideoPlayer(true);
  const onCloseVideoModal = () => setOpenVideoPlayer(false);

  // getting token from localStorage
  const token = JSON.parse(localStorage.getItem("token"));

  // request configuration
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // method for searching vehicle using id
  async function searchVehicle(vehicleId, vehicleType) {
    axios
      .post(
        requests.search_vehicle,
        {
          vehicle_id: vehicleId,
          vehicle_type: vehicleType,
        },
        config
      )
      .then((res) => {
        dispatchAppState({
          type: "add-images",
          payload: res.data.data,
        });

        if (res.data.status == false) {
          setError(res.data.message);
        } else if (res.data.status) {
          setError("");
        }

        if (res.data.data.length > 0) {
          setBatchIdInputVal(res.data.data[0].batch_id);
        } else {
          setBatchIdInputVal('');
        }
      });
  }

  // method for searching vehicle using id
  async function getVehicleImages(vehicleId, vehicleType) {
    axios
      .post(
        requests.getVehicleImages,
        {
          vehicle_id: vehicleId,
          vehicle_type: vehicleType,
        },
        config
      )
      .then((res) => {
        dispatchAppState({
          type: "add-images",
          payload: res.data.data,
        });
      });
  }

  // method for deleting image
  async function deleteVehicleimage(vehicleId) {
    axios
      .delete(`${requests.deleteVehicleImage}/${vehicleId}`, config)
      .then((res) => {
        if (res.data.status === "success") {
          toast.success("Image deleted sucessfully", {
            position: "top-center",
          });

          getVehicleImages(vehicleIdInputVal, selectedVehicleType);
        }
      });
  }

  useEffect(() => {
    var data = appState.images;

    var list = [];
    for (let i = 0; i < data.length; i++) {
      var newData = {
        value: data[i].id,
        user_id: data[i].user_id,
        content: (
          <div className="each-img" key={i}>
            <div className="close">
              <span
                id={data[i].id}
                onClick={(e) => {
                  deleteVehicleimage(e.currentTarget.id);
                  setItems(items.filter((eachItem) => eachItem));
                }}
              >
                <ion-icon name="close-circle-outline"></ion-icon>
              </span>
            </div>
            <img src={data[i].url_thump} alt="" className="vehicle-img" />
          </div>
        ),
      };
      list.push(newData);
    }

    setDndList(list);
    setItems(appState.images);

    // array variable to store video url
    let videoFiles = [];
    // filtering video files
    appState.images.forEach((eachFile) => {
      if (eachFile.file_type === "video") {
        videoFiles.push({
          videoId: eachFile.id,
          videoUrl: eachFile.url_default,
        });
      }
    });

    setVideoList(videoFiles);
  }, [appState]);

  const onSortEnd = (oldIndex, newIndex) => {
    setItems((array) => arrayMove(array, oldIndex, newIndex));
    setOnSorting(true);
  };

  // onpress of update button when we shuffle the items position
  const onUpdate = () => {
    var batch_id  = batchIdInputVal;

    if(batch_id) {
      var vehicle_array = [];
      for (let i = 0; i < items.length; i++) {
        var data = {
          id: items[i].id,
          user_id: items[i].user_id,
          position: i,
        };
        vehicle_array.push(data);
      }
      var data = {
        vehicle_id: vehicleIdInputVal,
        batch_id: batchIdInputVal,
        vehicle_array: vehicle_array,
      };

      setOnSorting(false);

      axios.post(requests.updatePosition, data, config).then((res) => {
        if (res.data.status === "success") {
          toast.success("position updated successfully", {
            position: "top-center",
          });
        }

        dispatchAppState({
          type: "add-images",
          payload: res.data.data,
        });

        if (res.data.status == false) {
          setError(res.data.message);
        } else if (res.data.status) {
          setError("");
        }
      });
    } else {
      setError("Please enter the vehicles Batch ID");
    }
  };

  // onclick of play button of a video file
  const onClickPlayBtn = (e) => {
    let idStr = e.currentTarget.id;
    let extractedNumber = parseInt(idStr.match(/(\d+)/)[0]);

    if (extractedNumber) {
      let url;
      videoList.forEach((eachVideo) => {
        if (eachVideo.videoId === extractedNumber) {
          url = eachVideo.videoUrl;
          setVideoUrl(`${url}`);
        }
      });
    }
    onOpenVideoModal();
  };

  return (
    <div className="main-content">
      <ToastContainer></ToastContainer>
      {/* modal winow with file uploader */}
      <ModalWindow
        open={open}
        onCloseModal={onCloseModal}
        onOpenModal={onOpenModal}
      >
        <FileUploader
          getVehicleImages={getVehicleImages}
          vehicleIdInputVal={vehicleIdInputVal}
          batchIdInputVal={batchIdInputVal}
          selectedVehicleType={selectedVehicleType}
        />
      </ModalWindow>

      {/* video modal with react player for video popup */}
      <VideoModal
        open={openVideoPlayer}
        onCloseModal={onCloseVideoModal}
        onOpenModal={onOpenVideoModal}
      >
        <ReactPlayer
          url={`${videoUrl}`}
          width="100%"
          height="calc(100vh - 100px)"
          controls
        />
      </VideoModal>

      <div className="select-category">
        <div className="offset"></div>
        <div className="vehicle-types">
          <div className="select-category-text">Select Category</div>
          <div className="vehicle-icons">
            <div className="each-type">
              <img
                src={
                  selectedVehicleType === "cars"
                    ? CarCategory1
                    : CarCategory1Unactive
                }
                height="60"
                alt=""
              />
              <div className="type-text">Cars</div>
              <input
                type="radio"
                name="vehicle-category"
                className="custom-radio"
                value="cars"
                onChange={(e) => {
                  setSelectedVehicleType(e.target.value);
                  setVehicleIdInputVal("");
                  setBatchIdInputVal("");
                  dispatchAppState({ type: "add-images", payload: [] });
                }}
              />
            </div>
            <div className="each-type">
              <img
                src={
                  selectedVehicleType === "lcv"
                    ? CarCategory2
                    : CarCategory2Unactive
                }
                height="60"
                alt=""
              />
              <div className="type-text">LCV</div>
              <div className="input">
                <input
                  type="radio"
                  name="vehicle-category"
                  className="custom-radio"
                  value="lcv"
                  onChange={(e) => {
                    setSelectedVehicleType(e.target.value);
                    setVehicleIdInputVal("");
                    dispatchAppState({ type: "add-images", payload: [] });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="add-new-vehicle">
          {/* <div className="btn">
            <Modal />
          </div> */}
        </div>
      </div>
      <div className="vehicle-id-form">
        <div className="form">
          <div className="title-text">
            <span className="bold">Vehicle ID</span>
            <span className="normal">Or Find vehicle</span>
          </div>
          <div className="input">
            <input
              type="text"
              id="vehicle_id"
              value={vehicleIdInputVal}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                // check out if entered value has space
                if (e.currentTarget.value.includes(" ")) {
                  e.currentTarget.value = e.currentTarget.value.replace(
                    /\s/g,
                    ""
                  );
                }

                setVehicleIdInputVal(e.target.value);
                if (e.target.value !== "") {
                  searchVehicle(e.target.value, selectedVehicleType);
                }
                if (e.target.value === "") {
                  dispatchAppState({
                    type: "add-images",
                    payload: [],
                  });
                }
              }}
              placeholder="Enter Vehicle id"
            />
            <div className="error-msg">{vehicleIdInputVal ? error : ""}</div>
          </div>

          <div className="input">
            <input
                type="text"
                defaultValue={batchIdInputVal}
                value={batchIdInputVal}
                id="batch_id"
                placeholder="Enter Vehicle Batch ID"
                onChange={(e) => {
                  setBatchIdInputVal(e.target.value)
                  if(items.length > 0) {
                    setOnSorting(true)
                  }
                }}
            />
          </div>
        </div>
      </div>
      <div className="images">
        <div className="top">
          <div className="left">
            {/* <span>
              <ion-icon name="eye-outline"></ion-icon>
            </span>
            <a href="#">View in Aws...</a> */}
          </div>
          <div className="right">Upload a maximum of 30 images</div>
        </div>
        <div className="bottom-wrapper">
          <div className="imgs-container">
            <SortableList
              onSortEnd={onSortEnd}
              className="imgs-container list"
              draggedItemClassName="dragged"
            >
              {items.map((data) => (
                <SortableItem key={data.id} className="each-img item">
                  <div className="each-img">
                    <div className="close">
                      <span
                        id={data.id}
                        onClick={(e) => {
                          deleteVehicleimage(e.currentTarget.id);
                          console.log("enter");
                          setItems(
                            items.filter(
                              (eachItem) =>
                                parseInt(eachItem.id) !==
                                parseInt(e.currentTarget.id)
                            )
                          );
                        }}
                      >
                        <ion-icon name="close-circle-outline"></ion-icon>
                      </span>
                    </div>

                    {data.file_type === "video" ? (
                      <div
                        id={`video-${data.id}`}
                        className="video-icon"
                        onClick={(e) => onClickPlayBtn(e)}
                      >
                        <ion-icon name="play-outline"></ion-icon>
                      </div>
                    ) : null}

                    <img
                      style={{ width: "15rem" }}
                      src={
                        data.file_type === "image"
                          ? data.url_thump
                          : data.video_thumb_url
                      }
                      alt=""
                      className="vehicle-img"
                    />
                  </div>
                </SortableItem>
              ))}
            </SortableList>

            <div
              className="each-img add-more"
              onClick={() => {
                if (!vehicleIdInputVal) {
                  toast.warn("Please enter the vehicle ID first", {
                    position: "top-center",
                  });
                } else if (!batchIdInputVal) {
                  toast.warn("Please enter the vehicle batch ID first", {
                    position: "top-center",
                  });
                }
              }}
            >
              <div className="icon">
                <ion-icon name="add-outline"></ion-icon>
              </div>
              {vehicleIdInputVal && batchIdInputVal ? (
                <button className="model-trigger" onClick={onOpenModal}>
                  Open modal
                </button>
              ) : null}
            </div>
          </div>
          {onSorting && (
            <div>
              <div className="update-sorting-btn" onClick={onUpdate}>
                Update
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

{
  /* drag and drop model dropzone will be trigger from here */
}

{
  /* <DragAndDropModel vehicleIdInputVal={vehicleIdInputVal}>
                <DropZone
                  vehicleIdInputVal={vehicleIdInputVal}
                  selectedVehicleType={selectedVehicleType}
                />
              </DragAndDropModel> */
}
